export class ToggleNavigation {
  // Constructor
  constructor(selector, removeSelector = null) {
    // Selector of the element that opens the menu
    this.selector = selector;
    // Optional selector of the element that should receive focus
    this.removeSelector = removeSelector || selector;
    // Body element to add or remove the 'nav-open' class
    this.body = document.querySelector('body');
    // Save the element that opens the menu
    this.navElement = document.querySelector(selector);
    // Bind the method to handle keyup events to the object
    this.handleKeyup = this.handleKeyup.bind(this);
    // Add event listener for opening the menu
    this.navElement.addEventListener('click', this.openNav.bind(this));
  }

  // Method to open the menu
  openNav() {
    // Add the 'nav-open' class to the body
    this.body.classList.add('nav-open');
    // Remove the event listener for opening the menu
    this.navElement.removeEventListener('click', this.openNav.bind(this));
    // Add event listener for closing the menu
    this.navElement.addEventListener('click', this.closeNav.bind(this));
    // Add event listener for keyup events to close the menu when Escape is pressed
    document.addEventListener('keyup', this.handleKeyup);
  }

  // Method to close the menu
  closeNav() {
    // Remove the 'nav-open' class from the body
    this.body.classList.remove('nav-open');
    // Remove the event listener for closing the menu
    this.navElement.removeEventListener('click', this.closeNav.bind(this));
    // Add event listener for opening the menu
    this.navElement.addEventListener('click', this.openNav.bind(this));
    // Remove event listener for keyup events
    document.removeEventListener('keyup', this.handleKeyup);
    // Give focus to the element that should receive focus after the menu is closed
    document.querySelector(this.removeSelector).focus();
  }

  // Method to handle keyup events
  handleKeyup(event) {
    // Close the menu when Escape is pressed
    if (event.key === 'Escape') {
      this.closeNav();
    }
  }
}

